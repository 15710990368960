<template>
  <div class="inter-wrapper">
    <HeaderInter :showbackbtn="showbackbtn" />
    <div class="inter-content">
      <div class="alert-area container-fluid">
        <b-icon icon="exclamation" class="icon-failure" />
        <h3>付款失敗</h3>
        <p v-if="$route.query.msg" class="mt-0 text-danger">
          {{ $route.query.msg }}
        </p>
        <div class="btn-area">
          <button
            class="btn btn-next line"
            type="button"
            @click="toItemDetail()"
          >
            返回
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderInter from '@/components/HeaderInter'

export default {
  name: 'OrderFail',
  components: {
    HeaderInter
  },
  data() {
    return {
      code: null,
      msg: null,
      backPath: '/product',
      showbackbtn: false
    }
  },
  created() {
    const host = new URL(window.location.href || document.location.href)
    this.code = host.searchParams.get('code')
    this.msg = host.searchParams.get('msg')

    const orderItem = this.$store.getters.orderItem.item_id
    if (orderItem) {
      this.backPath = '/product/detail/' + orderItem
    }
    if (this.code === null) {
      this.code = '發生錯誤'
    }
    if (this.msg === null) {
      this.msg = '金流交易失敗'
    }

    // 清除storage
    this.$store.commit('SET_DEFAULT_INFO')
    this.$store.commit('SET_DEFAULT_ITEM')
    this.$store.commit('SET_PAYMENT_ORDER_ID', null)
  },
  mounted() {},
  methods: {
    toItemDetail() {
      this.$router.push(this.backPath)
    }
  }
}
</script>
